<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">Frequencies Manage</h4>
    </div>
    <div class="col-md-12 card">
      <div class="card-body row">
        <div class="col-sm-1">
          <p-button class="no-margin" type="default" block outline round  @click="openAddModal()"> <i class="fa fa-plus"></i> </p-button>
        </div>
        <div class="col-sm-5"  v-if="pagination">
          <el-select
            class="select-default"
            v-model="pagination.per_page"
            placeholder="Per page"
            @change="changePerPage()"
          >
            <el-option
              class="select-default"
              v-for="item in perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input
              class="input-sm"
              placeholder="Search"
              v-model="searchQuery"
              addon-right-icon="nc-icon nc-zoom-split"
            ></fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table
            class="table-striped"
            :data="tableData"
            border
            style="width: 100%;"
            height="600"
          >
            <el-table-column
              v-for="(column) in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
              :sortable="column.sortable || false"
              :align="column.align"
              :header-align="column.align"
              v-if="!column.hidden"
              :fixed="column.fixed || false"
              :formatter="column.formatter || null"
            ></el-table-column>

            <el-table-column :min-width="150" fixed="right" class-name="td-actions" label="Actions" align="center">
              <template slot-scope="props">
                <!-- <p-button type="info" size="sm" icon @click="handleLike(props.$index, props.row)">
                  <i class="fa fa-eye"></i>
                </p-button> -->
                <p-button
                  type="success"
                  size="sm"
                  icon
                  @click="handleEdit(props.$index, props.row)"
                >
                  <i class="fa fa-edit"></i>
                </p-button>
                <p-button
                  v-if="!props.row.isCritical"
                  type="danger"
                  size="sm"
                  icon
                  @click="handleDelete(props.$index, props.row)"
                >
                  <i class="fa fa-trash"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info"  v-if="pagination">
          <p
            class="category"
          >Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries</p>
        </div>
        <div class="col-sm-6"  v-if="pagination">
          <p-pagination
            class="pull-right"
            v-model="pagination.current_page"
            :per-page="Number.parseInt(pagination.per_page)"
            :total="pagination.total"
            @input="changePage()"
          ></p-pagination>
        </div>
      </div>
    </div>

    <modal
      :show.sync="modals.add"
      headerClasses="justify-content-center"
      footer-classes="justify-content-center"
      >
      <h5 slot="header" class="modal-title">Frequencies Manage</h5>
      <form>
        <div class="row">
          <div class="form-group col-12">
            <label for>Value</label>
            <fg-input
              name="value"
              v-model="model.value"
              placeholder="Frequency Value"
              v-validate="modelValidations.value"
              :error="getError('value')"
            >
            </fg-input>
          </div>
        </div>
      </form>
      <template slot="footer">
        <p-button type="success" @click="validate()">Save</p-button>
      </template>
    </modal>

  </div>
</template>


<script>
import Vue from "vue";
import { Modal } from "src/components/UIComponents";
import { Table, TableColumn, Select, Option, Slider, DatePicker } from "element-ui";
import PPagination from "src/components/UIComponents/Pagination.vue";
// import users from "../Tables/users.js";
import swal from 'sweetalert2'
import { mapGetters } from "vuex";

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
Vue.use(Slider);

export default {
  components: {
    [DatePicker.name]: DatePicker,
    PPagination,
    Modal,
  },
  mounted() {
    this.$store.dispatch("fetchFrequencies", { per_page: 10, page: 1 });
  },
  watch: {
      searchQuery: function (val) {
          this.$store.dispatch("fetchFrequencies", {
              searchQuery: this.searchQuery,
              per_page: this.pagination.per_page,
              page: this.pagination.current_page
          }); 
      }
  },
  computed: {
    ...mapGetters({
      tableData: "getFrequencies",
      pagination: "getPagination"
    }),
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      // if (!this.searchQuery) {
      //   if(this.locations)
      //   this.pagination.total = this.locations.length;
      //   else
      //   this.pagination.total = 0
      //   return this.pagedData;
      // }
      // let result = this.locations.filter(row => {
      //   let isIncluded = false;
      //   for (let key of this.propsToSearch) {
      //     let rowValue = row[key].toString();
      //     if (rowValue.includes && rowValue.includes(this.searchQuery)) {
      //       isIncluded = true;
      //     }
      //   }
      //   return isIncluded;
      // });
      // this.pagination.total = result.length;
      // return result.slice(this.from, this.to);
    }
  },
  data() {
    return {
      scope: 'add',
      modals: {
        add: false,
        edit: false
      },
      perPageOptions: ["10", "30", "50", "100", "250", "500"],
      searchQuery: "",
      propsToSearch: [
        "value",
      ],
      tableColumns: [
        {
          prop: "id",
          label: "ID",
          minWidth: 50,
          hidden: true,
          align: "center"
        },
        {
          prop: "value",
          label: "Value",
          minWidth: 200,
          fixed: false,
          sortable: true,
          align: "left"
        },
      ],
      model: {
        id: null,
        value: null,
      },
      modelValidations: {
        value: {
          required: true
        },
      }
    };
  },
  methods: {
    changePerPage() {
      this.$store.dispatch("fetchFrequencies", {
        per_page: this.pagination.per_page,
        page: 1,results, searchQuery : this.searchQuery
      });
    },
    changePage() {
      this.$store.dispatch("fetchFrequencies", {
        per_page: this.pagination.per_page,
        page: this.pagination.current_page, searchQuery : this.searchQuery
      });
    },
    handleEdit(index, row) {
      this.model.id = row.id;
      this.model.value = row.value;
      this.scope = 'edit';
      this.modals.add = true;
    },
    handleDelete(index, row) {
      // this.$store.dispatch("deleteFrequency", row.id);
      swal({
          title: "Are you sure?",
          text: `You won't be able to revert this!`,
          showCancelButton: true,
          confirmButtonClass: "btn btn-danger btn-fill",
          cancelButtonClass: "btn btn-default btn-fill",
          confirmButtonText: "Delete it!",
          buttonsStyling: false
      })
      .then(result => { this.$store.dispatch("deleteFrequency", row.id); })
      .catch(cancel => { console.log(cancel) });
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          this.modals.add = false;
          switch(this.scope){
            case 'add'  : this.$store.dispatch("createFrequency", this.model); break;
            case 'edit' : this.$store.dispatch("updateFrequency", this.model); break;
          }
        }
      });
    },
    openAddModal(){
      this.model.id = null;
      this.model.value = null;
      this.scope = 'add';
      this.modals.add = true;
    }
  }
};
</script>

<style lang="scss">
.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
}

button.no-margin{
  margin: 0;
}

.el-table,
.el-table tr,
.el-table thead th {
  background-color: white !important;
}

.form-group .el-input-number {
  width: 35%;
  outline: none;
}
.el-input--small .el-input__inner {
  border-radius: 50px;
}
</style>
